import axios from "axios";
import UserService from "../services/user.service";
import { config } from "../config";

class AuthService {
  login(user) {
    return axios
      .post(`${config["apiUrl"]}auth/login`, {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.status == 200) {
          localStorage.setItem("userToken", JSON.stringify(response.data));
          return UserService.getUserInfo().then(userInfo => {
            if (userInfo) {
              userInfo["token"] = response.data.token;
              localStorage.setItem("user", JSON.stringify(userInfo));
              return userInfo;
            }
          });
        }
      }).then((userInfo) => {
        return UserService.getUserAcls().then(acls => {
          if (acls) {
            userInfo["moreInfo"] = acls
            localStorage.setItem("user", JSON.stringify(userInfo));
              return userInfo;
          }
        })
      });
  }

  logout() {
    sessionStorage.removeItem("selectedNodes");
    localStorage.removeItem("user");
  }
}

export default new AuthService();
